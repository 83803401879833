/* eslint-disable arrow-body-style */
import React, { useCallback, useState } from 'react';
import DefaultLayout from 'src/components/layout/DefaultLayout';
import PaymentDialog from 'src/components/common/PaymentDialog';
import { PaymentOrderDescription } from 'src/types';
import styles from './Billing.module.scss';
import SectionQuestions from './SectionQuestions';
import SectionHeader from './SectionHeader';
import { useBilling } from './useBilling';
import Copyright from '../../components/common/Copyright';

const Billing = () => {
  const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const {
    price,
    getPaymentUrls,
    paymentSucceed,
    paypalPaymentProcessing,
    cardPaymentProcessing,
    paymentFailed,
  } = useBilling();

  const onGetReport = () => {
    setIsPaymentDialogVisible(true);
  };

  const onPaymentDialogClose = () => {
    setIsPaymentDialogVisible(false);
  };

  const onPaymentSuccess = useCallback((orderData: PaymentOrderDescription) => {
    setIsPaymentDialogVisible(false);
    paymentSucceed(orderData);
  }, [paymentSucceed]);

  return (
    <DefaultLayout>
      <div className={styles.wrapper}>
        <SectionHeader onGetReport={onGetReport} isAgree={isAgree} setIsAgree={setIsAgree} />
        <SectionQuestions onGetReport={onGetReport} isAgree={isAgree} />
        <div className={styles.copyright}>
          <Copyright />
        </div>
      </div>
      <PaymentDialog
        onPaymentFailed={paymentFailed}
        onPaypalPaymentProcessing={paypalPaymentProcessing}
        onCardPaymentProcessing={cardPaymentProcessing}
        onPaymentSuccess={onPaymentSuccess}
        isOpen={isPaymentDialogVisible}
        onClose={onPaymentDialogClose}
        price={price}
        getPaymentUrls={getPaymentUrls}
      />
    </DefaultLayout>
  );
};

export default Billing;
