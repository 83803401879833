import React from 'react';
import styles from './ProcessingScreenLoaderCircle.module.scss';

export type ProcessingScreenLoaderCircleProps = {
  value: number;
}

const ProcessingScreenLoaderCircle = ({ value }: ProcessingScreenLoaderCircleProps) => (
  <svg viewBox="0 0 35 35" className={styles.circle}>
    <circle
      className="donut-hole"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
    >
    </circle>
    <circle
      className="donut-ring"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      strokeOpacity="0.3"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
    >
    </circle>
    <circle
      className="donut-segment"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      strokeDasharray={`${value} ${100 - value}`}
      strokeDashoffset={`${value}`}
    >
    </circle>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="45"
        y2="-9.5"
        x2="53.1842"
        y1="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A4DBC" />
        <stop offset="1" stopColor="#F2994A" />
      </linearGradient>
    </defs>
  </svg>
);

export default ProcessingScreenLoaderCircle;
