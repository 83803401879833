import React from 'react';
import cn from 'classnames';
import address from 'src/assets/images/address.svg';

import styles from './Copyright.module.scss';

const Copyright = ({ className = '' }) => (
  <div className={cn(styles.wrapper, className)}>
    <img className={styles.image} src={address} alt="address" width={230} height={38} />
  </div>
);

export default Copyright;
