import { scroller as scroll } from 'react-scroll';

const scrollToBlock = (blockID: string): void => {
  scroll.scrollTo(blockID, {
    duration: 500,
    delay: 100,
    smooth: true,
    offset: -250,
  });
};

export default scrollToBlock;
