import { QuizData, StepTypes } from 'src/types';
import { BASE_ROUTES } from 'src/constants/routes';

import introIllustration from 'src/assets/images/scorpio/scorpio.svg';
import genderIllustration from 'src/assets/images/scorpio/gender.svg';
import feelIllustration from 'src/assets/images/scorpio/feel.svg';
import dreamIllustration from 'src/assets/images/scorpio/sleeping.svg';
import worryIllustration from 'src/assets/images/scorpio/worry.svg';
import { QUIZ_ANALYTIC_EVENTS } from 'src/services/analytics';

export const quizResources: QuizData = {
  baseUrl: BASE_ROUTES.QUIZ,
  nextStage: BASE_ROUTES.BILLING,
  steps: [
    {
      id: 'intro',
      screenType: StepTypes.INTRO,
      content: {
        title: 'Discover your ideal partner and the most compatible signs with Scorpio',
        subtitle: `Take a short quiz and find out how you fare now.
         Get a full compatibility report with insights and advice.`,
        illustration: introIllustration,
      },
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_START_OPEN,
      },
    },
    {
      id: 'gender',
      screenType: StepTypes.GENDER,
      content: {
        title: 'Select your gender',
        subtitle: 'This will make your report more personalized and accurate',
        illustration: genderIllustration,
        shouldShowNonBinary: true,
      },
      dataKey: 'gender',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_GENDER_OPEN,
      },
    },
    {
      id: 'feel',
      screenType: StepTypes.SINGLE_ANSWER,
      content: {
        answers: [
          { text: 'In the morning', emoji: 'slightly_smiling_face' },
          { text: 'During the afternoon', emoji: 'thinking_face' },
          { text: 'Late at night', emoji: 'face_with_hand_over_mouth' },
        ],
        title: 'When do you feel your best?',
        illustration: feelIllustration,
      },
      dataKey: 'feel',
    },
    {
      id: 'dream',
      screenType: StepTypes.SINGLE_ANSWER,
      content: {
        answers: [
          { text: 'Falling', emoji: 'slightly_smiling_face' },
          { text: 'Searching for something', emoji: 'thinking_face' },
          { text: 'Flying', emoji: 'wink' },
        ],
        title: 'You often dream that you are?',
        illustration: dreamIllustration,
      },
      dataKey: 'dream',
    },
    {
      id: 'worry',
      screenType: StepTypes.SINGLE_ANSWER,
      content: {
        answers: [
          { text: 'Yes, very often', emoji: 'slightly_smiling_face' },
          { text: 'It comes and goes', emoji: 'thinking_face' },
          { text: 'No, never', emoji: 'wink' },
        ],
        title: 'Do you find yourself worrying or getting anxious often?',
        illustration: worryIllustration,
      },
      dataKey: 'worry',
    },
    {
      id: 'email',
      screenType: StepTypes.EMAIL,
      content: {
        title: 'What is your email address?',
        subtitle: 'Enter email address to get access to personalized love reading and discover '
          + 'relationship experts you were matched with',
        illustration: dreamIllustration,
        tip: 'We respect your privacy and are committed to protecting your personal data',
      },
      dataKey: 'email',
    },
    {
      id: 'processing',
      screenType: StepTypes.PROCESSING,
      content: {
        title: 'Please wait a moment while we working on your compatibility report...',
        stages: [
          'Analysing your answers...',
          'Designing your natal chart...',
          'Checking your compatibility with other signs...',
          'Creating your astrology profile...',
          'Finalizing your report...',
        ],
      },
      canNotBackToScreen: true,
    },
  ],
};
