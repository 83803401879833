/* eslint-disable quotes,react/no-unescaped-entities */
import React from 'react';
import DefaultLayout from 'src/components/layout/DefaultLayout';
import styles from './ContactUs.module.scss';

const ContactUs = () => (
  <DefaultLayout>
    <section className={styles.contact}>
      <h1 className={styles.title}>Contact Us</h1>
      <p className={styles.text}>
        We will be glad to assist you via email. Please send your questions and
        feedback to
        <a href="mailto:support@astro-sign.org">
          {" "}
          support@astro-sign.org
        </a>
      </p>
    </section>
  </DefaultLayout>
);

export default ContactUs;
