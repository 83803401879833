import HttpClient from 'src/api/HttpClient';
import { CookiesService } from './CookiesService';
import { PurchaseService } from './PurchaseService';
import { AmplitudeService, AnalyticsService } from './analytics';
import { AstrologerService } from './AstrologerService';
import { AuthService } from './AuthService';
import { GoogleAnalyticsService } from './analytics/GoogleAnalyticsService';

export * from './CookiesService';
export * from './analytics/AnalyticsService';
export * from './PurchaseService';
export * from './AstrologerService';
export * from './AuthService';
export * from './ZodiacService';

export interface Services {
  cookiesService: CookiesService,
  analyticsService: AnalyticsService,
  purchaseService: PurchaseService,
  astrologerService: AstrologerService;
  authService: AuthService;
}

export const initServices = async (): Promise<Services> => {
  const httpClient = new HttpClient();
  const cookiesService = new CookiesService();
  const purchaseService = new PurchaseService(httpClient);
  const astrologerService = new AstrologerService(httpClient);
  const authService = new AuthService(httpClient);

  const amplitudeService = new AmplitudeService(process.env.REACT_APP_AMPLITUDE_API_KEY || '');
  const googleAnalyticsService = new GoogleAnalyticsService();
  const analyticsService = new AnalyticsService(amplitudeService, googleAnalyticsService);

  return {
    cookiesService,
    analyticsService,
    purchaseService,
    astrologerService,
    authService,
  };
};
