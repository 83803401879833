/* eslint-disable arrow-body-style */
import useServices from 'src/hooks/useServices';
import { PurchaseProductsTypes } from 'src/services';
import { useHistory } from 'react-router-dom';
import { BASE_ROUTES } from 'src/constants/routes';
import { useCallback } from 'react';
import { PaymentOrderDescription } from 'src/types';
import { useSelector } from 'react-redux';
import { getEmail } from 'src/redux/user';

export function useBilling() {
  const history = useHistory();

  const email = useSelector(getEmail);

  // TODO add dynamic
  const price = '$9.99';

  const { purchaseService, analyticsService } = useServices();

  const getPaymentUrls = useCallback(async () => {
    return purchaseService.purchaseCompatibilityReportAndSubscription(email);
  }, [purchaseService, email]);

  const getDiscount = useCallback(() => {
    history.push(BASE_ROUTES.SUCCESS);
  }, [history]);

  const paymentSucceed = useCallback(
    (paymentData: PaymentOrderDescription) => {
      analyticsService.paymentSuccess({
        orderId: paymentData.order_id,
        productId: PurchaseProductsTypes.ASK_NEBULA_WEEKLY_999,
        price: paymentData.amount / 100,
      });
      history.push(BASE_ROUTES.SUCCESS);
    },
    [analyticsService, history],
  );

  const paymentFailed = useCallback(() => {
    analyticsService.paymentError();
  }, [analyticsService]);

  const cardPaymentProcessing = useCallback(() => {
    analyticsService.paymentCardChosen();
  }, [analyticsService]);

  const paypalPaymentProcessing = useCallback(() => {
    analyticsService.paymentPaypalChosen();
  }, [analyticsService]);

  return {
    price,
    getPaymentUrls,
    getDiscount,
    paymentSucceed,
    paymentFailed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
  };
}
