import React, { FC } from 'react';
import scrollToBlock from 'src/utils/scrollToBlock';
import ImagedPageSection, { DPIScales } from 'src/components/common/ImagedPageSection';
import InlineLink from 'src/components/common/InlineLink';
import {
  getPrivacyPolicyPagePath,
  getTermsPagePath,
  getSubscriptionPolicyPagePath,
} from 'src/constants';
import pngXl from 'src/assets/images/billing1/header/header-xl.png';
import pngXl2x from 'src/assets/images/billing1/header/header-xl-2x.png';
import webpXl from 'src/assets/images/billing1/header/header-xl.webp';
import webpXl2x from 'src/assets/images/billing1/header/header-xl-2x.webp';
import pngMd from 'src/assets/images/billing1/header/header-md.png';
import pngMd2x from 'src/assets/images/billing1/header/header-md-2x.png';
import webpMd from 'src/assets/images/billing1/header/header-md.webp';
import webpMd2x from 'src/assets/images/billing1/header/header-md-2x.webp';
import pngXs from 'src/assets/images/billing1/header/header-xs.png';
import pngXs2x from 'src/assets/images/billing1/header/header-xs-2x.png';
import webpXs from 'src/assets/images/billing1/header/header-xs.webp';
import webpXs2x from 'src/assets/images/billing1/header/header-xs-2x.webp';
import styles from './SectionHeader.module.scss';
import HeaderButton from './HeaderButton/HeaderButton';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

interface Props {
  onGetReport: () => void;
  isAgree: boolean;
  setIsAgree: (isAgree: boolean) => void;
}

const SectionHeader: FC<Props> = ({ onGetReport, isAgree, setIsAgree }) => {
  const handleAgree = () => setIsAgree(!isAgree);

  const scrollToButton = () => {
    scrollToBlock('paymentButton');
  };

  return (
    <ImagedPageSection
      originalUrl={sectionData.originalUrl}
      sources={sectionData.sources}
      holderClassName={styles.holder}
    >
      <HeaderButton buttonClassName={styles.button1} onScrollToButton={scrollToButton} />
      <HeaderButton
        onGetReport={onGetReport}
        isActive={isAgree}
        buttonClassName={styles.button2}
      />
      <div className={styles.agreement}>
        <label htmlFor="termsAgreement">
          <input
            type="checkbox"
            id="termsAgreement"
            onChange={handleAgree}
            checked={isAgree}
          />
          I agree to MOON
          {' '}
          <InlineLink className={styles.link} href={getTermsPagePath()}>
            Terms of use
          </InlineLink>
          ,
          {' '}
          <InlineLink className={styles.link} href={getSubscriptionPolicyPagePath()}>
            Subscription Policy
          </InlineLink>
          {' '}
          and
          {' '}
          <InlineLink className={styles.link} href={getPrivacyPolicyPagePath()}>
            Privacy Policy
          </InlineLink>
        </label>
      </div>
    </ImagedPageSection>
  );
};

export default SectionHeader;
