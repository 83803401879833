import React, { useEffect } from 'react';
import cn from 'classnames';

import { PaymentOrderDescription, PaymentOrderStatus } from 'src/types';
import { TMessageEventData } from './types';
import styles from './CardPayment.module.scss';

export type CardPaymentProps = {
    url: string;
    className?: string;
    onOrderDeclined: () => void;
    onOrderStartProcessing: () => void;
    onOrderApproved: (orderData: PaymentOrderDescription) => void;
}

const CardPayment = (props: CardPaymentProps) => {
  const {
    url, className, onOrderApproved, onOrderDeclined, onOrderStartProcessing,
  } = props;

  useEffect(() => {
    const listener = (event: MessageEvent<TMessageEventData>) => {
      if (!event) {
        return;
      }

      if (event.data.type !== 'orderStatus') {
        return;
      }

      const { order } = event.data.response;
      switch (order.status) {
        case PaymentOrderStatus.PROCESSING:
          onOrderStartProcessing();
          break;
        case PaymentOrderStatus.APPROVED:
        case PaymentOrderStatus.REFUNDED:
          onOrderApproved(order);
          break;
        case PaymentOrderStatus.DECLINED:
          onOrderDeclined();
          break;
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  },
  [
    url,
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  ]);

  return (
    <iframe title="card" src={url} className={cn(styles.root, className)} />
  );
};

export default CardPayment;
