import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useApp } from 'src/hooks/useApp';
import { ServicesContext } from 'src/context';
import styles from 'src/App.module.scss';
import { BASE_ROUTES } from 'src/constants/routes';
import Quiz from 'src/pages/Quiz';
// import CookiesAgreement from 'src/components/common/CookiesAgreement';
import Billing from 'src/pages/Billing';
import Terms from 'src/pages/Terms';
import Success from 'src/pages/Success';
import ContactUs from 'src/pages/ContactUs';

const App = () => {
  const { isAppLoaded, store, services } = useApp();

  if (!isAppLoaded || !store || !services) {
    return <>Loading</>;
  }

  return (
    <Provider store={store}>
      <ServicesContext.Provider value={services}>
        <div className={styles.app}>
          <Switch>
            <Route path={BASE_ROUTES.TERMS} exact>
              <Terms />
            </Route>

            <Route path={BASE_ROUTES.SUCCESS} exact>
              <Success />
            </Route>

            <Route path={BASE_ROUTES.CONTACT_US} exact>
              <ContactUs />
            </Route>

            <Route path={BASE_ROUTES.BILLING} exact>
              <Billing />
            </Route>

            <Route path={BASE_ROUTES.QUIZ}>
              {/* <CookiesAgreement /> */}
              <Quiz />
            </Route>

            <Redirect to={BASE_ROUTES.QUIZ} />
          </Switch>
        </div>
      </ServicesContext.Provider>
    </Provider>
  );
};

export default App;
