import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import Modal from 'src/components/common/Modal';
import { ReactComponent as CrossIcon } from 'src/assets/images/cross.svg';
import PaypalPayment from 'src/components/common/PaypalPayment';
import CardPayment from 'src/components/common/CardPayment';
import CircularProgress from 'src/components/common/CircularProgress';
import { PaymentOrderDescription } from 'src/types';
import styles from './PaymentDialog.module.scss';
import PaymentFailedDialogContent from './PaymentFailedDialogContent';

export type PaymentDialogProps = {
    className?: string;
    isOpen?: boolean;
    onClose?: () => void;
    price: string;
    getPaymentUrls: () => Promise<{
        paypalPaymentUrl: string;
        cardPaymentUrl: string;
    }>;
    onCardPaymentProcessing?: () => void;
    onPaypalPaymentProcessing?: () => void;
    onPaymentSuccess?: (order: PaymentOrderDescription) => void;
    onPaymentFailed?: () => void;
}

enum PaymentProcessSteps {
    INITIAL,
    IN_PROCESS,
    FAILED,
    SUCCESS
}

const PaymentDialog = (props: PaymentDialogProps) => {
  const {
    className,
    getPaymentUrls,
    isOpen = false,
    onClose,
    onPaymentFailed,
    onPaymentSuccess,
    onCardPaymentProcessing,
    onPaypalPaymentProcessing,
    price,
  } = props;

  const [paymentProcessStep, setPaymentProcessStep] = useState(PaymentProcessSteps.INITIAL);

  const [cardPaymentUrl, setCardPaymentUrl] = useState('');
  const [paypalPaymentUrl, setPaypalPaymentUrl] = useState('');

  const onModalClose = () => {
    if (paymentProcessStep === PaymentProcessSteps.IN_PROCESS) return;

    if (onClose) {
      onClose();
    }
  };

  const clearState = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.INITIAL);
    setCardPaymentUrl('');
    setPaypalPaymentUrl('');
  }, []);

  const onOrderDeclined = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.FAILED);
    if (onPaymentFailed) {
      onPaymentFailed();
    }
  }, [onPaymentFailed]);

  const onOrderCardStartProcessing = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.IN_PROCESS);
    if (onCardPaymentProcessing) {
      onCardPaymentProcessing();
    }
  }, [onCardPaymentProcessing]);

  const onOrderPaypalStartProcessing = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.IN_PROCESS);
    if (onPaypalPaymentProcessing) {
      onPaypalPaymentProcessing();
    }
  }, [onPaypalPaymentProcessing]);

  const onOrderApproved = useCallback((order: PaymentOrderDescription) => {
    setPaymentProcessStep(PaymentProcessSteps.SUCCESS);
    if (onPaymentSuccess) {
      onPaymentSuccess(order);
    }
  }, [onPaymentSuccess]);

  const getUrls = useCallback(async () => {
    try {
      setPaymentProcessStep(PaymentProcessSteps.IN_PROCESS);
      const urls = await getPaymentUrls();
      setPaypalPaymentUrl(urls.paypalPaymentUrl);
      setCardPaymentUrl(urls.cardPaymentUrl);
      setPaymentProcessStep(PaymentProcessSteps.INITIAL);
    } catch (err) {
      console.error(err);
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setPaymentProcessStep(PaymentProcessSteps.FAILED);
    }
  }, [onPaymentFailed, getPaymentUrls]);

  const tryAgain = () => {
    if (paymentProcessStep !== PaymentProcessSteps.FAILED) return;

    getUrls();
  };

  useEffect(() => {
    if (!isOpen) return () => {};

    getUrls();

    return () => clearState();
  }, [clearState, getUrls, isOpen]);

  const renderLoader = () => (
    <div className={styles.loaderWrapper}>
      <CircularProgress />
    </div>
  );

  const bodyClasses = cn({
    [styles.body]: true,
    [styles.bodyInProgress]: paymentProcessStep === PaymentProcessSteps.IN_PROCESS,
  });

  return (
    <Modal open={isOpen} onClose={onModalClose}>
      <div className={cn(styles.wrapper, className)}>
        {/* TODO(IconButton) */}
        <div role="button" onClick={onModalClose} className={styles.controlWrapper}>
          <CrossIcon className={styles.control} />
        </div>
        { paymentProcessStep !== PaymentProcessSteps.FAILED ? (
          <>
            <div className={styles.header}>
              <div className={styles.priceWrapper}>
                <span className={styles.priceLabel}>
                  Total:
                </span>
                {' '}
                <span className={styles.price}>
                  {price}
                </span>
              </div>
            </div>
            <div className={styles.bodyWrapper}>
              { paymentProcessStep === PaymentProcessSteps.IN_PROCESS && renderLoader() }
              <div className={bodyClasses}>
                <PaypalPayment
                  url={paypalPaymentUrl}
                  onOrderDeclined={onOrderDeclined}
                  onOrderStartProcessing={onOrderPaypalStartProcessing}
                  onOrderApproved={onOrderApproved}
                />
                <div className={styles.divider}>
                  <span className={styles.dividerLabel}>or</span>
                </div>
                <CardPayment
                  url={cardPaymentUrl}
                  onOrderDeclined={onOrderDeclined}
                  onOrderStartProcessing={onOrderCardStartProcessing}
                  onOrderApproved={onOrderApproved}
                />
              </div>
            </div>
          </>
        )
          : <PaymentFailedDialogContent onTryAgain={tryAgain} />}
      </div>
    </Modal>
  );
};

export default PaymentDialog;
