export enum BASE_ROUTES {
  QUIZ = '/flow',
  SHORT_QUIZ = '/flow/short',
  POST_QUIZ = '/flow/lp/intro-offer-1',
  LOVE_READING_1 = '/flow/lp/love-reading-1',
  EXTENDED_LANDING = '/flow/lp/extended-landing',
  BILLING = '/flow/lp/billing',
  COMPATIBILITY_REPORT = '/flow/compatibility-report',
  ADDITIONAL_DISCOUNT = '/flow/lp/additional-discount',
  NUMEROLOGY = '/flow/lp/numerology',
  TAROT = '/flow/lp/tarot',
  TERMS = '/flow/terms',
  SUCCESS = '/flow/success',
  CONTACT_US = '/flow/contact-us',
}
